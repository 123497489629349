//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { miceService } from "@/service/miceService";
export default {
  data() {
    return {
      historyCities: [],
      nowCities: [],
      nowData: {},
      dataQuery: {}
    };
  },
  created() {
    var hsCities = localStorage.getItem("hsCities");
    if (hsCities) {
      this.historyCities = JSON.parse(hsCities);
    }
    this._data.query = this.common.toLowerCase(this.$route.query);

    this.dataQuery = this._data.query;

    if (this._data.query.tenantid) {
      this.queryOpenedCities(this._data.query.tenantid);
    }
  },
  methods: {
    doClose: function() {
      //关闭页面
      if (window.webView) {
        window.webView.close();
      }
    },
    goIndex: function(item) {
      // 传入选定城市信息,存入localStorage
      // this.filterHsCities(item)
      var array = [];
      if (localStorage.getItem("hsCities")) {
        array = JSON.parse(localStorage.getItem("hsCities"));
        // array.push(item)
        if (array.length < 8) {
          let isPush = false;
          for (let i = 0, L = array.length; i < L; i++) {
            if (array[i].txt === item.txt) {
              isPush = true;
            }
          }
          if (!isPush) {
            array.push(item);
          }
        } else {
          let isPush = false;
          for (let i = 0, L = array.length; i < L; i++) {
            if (array[i].txt === item.txt) {
              isPush = true;
            }
          }
          if (!isPush) {
            array.splice(0, 1);
            array.push(item);
          }
        }
      } else {
        array.push(item);
      }
      localStorage.setItem("hsCities", JSON.stringify(array));
      let goIndex = true;
      var pageRouter = goIndex ? "Index" : "hospitalList";
      this.$router.push({
        name: pageRouter,
        query: {
          cityId: item.itsExtData.pingCityId,
          hospitalId: this.dataQuery.hospitalid,
          tenantId: this.dataQuery.tenantid,
          proposalType: this.dataQuery.proposaltype,
          rejecttabs: this.dataQuery.rejecttabs,
          hospitalfixed: this.dataQuery.hospitalfixed
        }
      });
    },
    queryOpenedCities: function(id) {
      // 获取已上架城市
      var params = {
        tenantId: id
      };
      var _this = this;
      miceService
        .queryOpenedCities(params)
        .then(res => {
          _this.nowCities = res.content;
        })
        .catch(err => {
          _this.$router.push({ name: "Error" });
        });
    }
  }
};
